<template>
  <CRow>
    <CCol sm="12">
      <CCard class="mt-4">
        <CCardHeader>
          <CIcon name="cil-description" class="mr-3"/>
          <h5>Protokoll</h5>
        </CCardHeader>
        <CCardBody>
            <CForm @submit.prevent="newEntry()">
              <CRow class="mt-3">
                <CCol sm="5">
                  <Firma v-model="kunde"/>
                </CCol>
                <CCol sm="3">
                  <Datum v-model="datum"/>
                  <Abgeschlossen v-model="abgeschlossen"/>
                </CCol>
                <CCol sm="4">
                  <Leistung v-model="leistung"/>
                </CCol>
              </CRow>

              <CRow>
                <CCol sm="12">
                  <Projekt v-model="projekt" :kunde="kunde"/>
                </CCol>
              </CRow>

              <CRow class="mt-3">
                <CCol sm="12">
                  <Arbeitsbeschreibung v-model="arbeitsbeschreibung"/>
                </CCol>
              </CRow>

              <CRow>
                <CCol sm="12">
                  <Material v-model="material" :material-vorgabe="materialVorgabe"/>
                </CCol>
              </CRow>

              <CRow class="mt-3">
                <CCol sm="12">
                  <Arbeitszeit v-model="arbeitszeit" :zukuenftig-berechnen="zukuenftigBerechnen"/>
                </CCol>
              </CRow>

              <CRow class="mt-3">
                <CCol sm="12">
                  <Tageskalender v-model="tageskalender" />
                </CCol>
              </CRow>

              <CRow class="mt-3">
                <CCol sm="12">
                  <CSpinner color="" v-show="isSubmitting()"></CSpinner>
                  <span class="fs-6" v-show="isSubmitting()">wird gespeichert...</span>
                  <CButton
                    type="submit"
                    :color="btnColor()"
                    class="btn-protokoll-submit btn-block font-weight-bold pt-3 pb-3"
                  >
                    <CIcon name="cil-library-add"/>
                    &nbsp;
                    Protokoll speichern
                  </CButton>

                </CCol>
              </CRow>
              <CModal
                title='Protokoll erfolgreich angelegt'
                color="success"
                size="m"
                :show="successModal"
              >
                Das Protokoll wurde gespeichert! Wollen sie das Protokoll drucken?
                <template #footer>
                  <div style="float: left">
                    <CButton  color="default" @click="closeSite" class="mr-2">Schließen</CButton>
                  </div>
                  <div>
                    <CButton @click="print" color="info" class="mr-2">Drucken</CButton>
                  </div>
                </template>
              </CModal>
            </CForm>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Firma from './components/Firma'
import Datum from './components/Datum'
import Abgeschlossen from './components/Abgeschlossen'
import Leistung from '@/components/LeistungsAuswahl'
import Projekt from './components/Projekt'
import Arbeitsbeschreibung from './components/Arbeitsbeschreibung'
import Material from './components/Material'
import Arbeitszeit from './components/Arbeitszeit'
import Tageskalender from './components/Tageskalender'
import Vue from 'vue'
import moment from 'moment/moment'

export default {
  name: 'MitarbeiterEdit',
  components: {
    Firma,
    Datum,
    Abgeschlossen,
    Leistung,
    Projekt,
    Arbeitsbeschreibung,
    Material,
    Arbeitszeit,
    Tageskalender
  },
  created () {
  },
  data () {
    return {
      submitting: false,
      kunde: '',
      datum: null,
      abgeschlossen: true,
      leistung: null,
      projekt: null,
      arbeitsbeschreibung: '',
      material: [],
      arbeitszeit: [],
      successModal: false,
      successData: {},
      tageskalender: true,
      materialVorgabe: [],
      materialVorgabeDefault: [
        {
          id: 1,
          stueckzahl: 1,
          artikelnr: '-',
          material: 'Klein-/Verdrahtungs-/u. Befestigungsmaterial'
        },
        {
          id: 2,
          stueckzahl: 1,
          artikelnr: '-',
          material: 'Anteilige Fahrzeugpauschale'
        }
      ],
      zukuenftigBerechnen: []
    }
  },
  mounted () {
    this.setDefaults()
  },
  watch: {
    kunde: function () {
      this.projekt = ''
      this.loadArbeitszeitenAuto()
    },
    projekt: function () {
      this.loadArbeitszeitenAuto()
    }
  },
  methods: {
    setDefaults () {
      this.kunde = ''
      this.datum = null
      this.abgeschlossen = true
      this.leistung = null
      this.projekt = null
      this.arbeitsbeschreibung = ''
      this.material = []
      this.submitGoing = false
      this.arbeitszeit = []
      this.tageskalender = true
      this.materialVorgabe = this.materialVorgabeDefault
    },
    isSubmitting: function () {
      return this.submitting
    },
    canSubmit: function () {
      let disabled = false

      if (this.submitGoing) {
        disabled = true
      }

      if (!this.kunde) {
        disabled = true
      }
      if (!this.leistung) {
        disabled = true
      }
      if (this.arbeitsbeschreibung.length === 0) {
        disabled = true
      }
      if (this.material.length === 0 && this.materialVorgabe.length === 0) {
        disabled = true
      }
      if (this.arbeitszeit.length === 0) {
        disabled = true
      }
      return disabled
    },
    btnColor: function () {
      if (!this.canSubmit()) {
        return 'success'
      } else {
        return 'success'
      }
    },
    newEntry: function () {
      const self = this
      if (!this.submitGoing && this.arbeitsbeschreibung && this.datum && this.kunde.kundenid && this.leistung) {
        this.submitGoing = true
        // An diesem Punkt sollte das Formular validiert und fertig sein
        this.submitting = true

        Vue.axios.post('/protokoll/post', {
          datum: this.dateFormatter(this.datum),
          arbeitsbeschreibung: this.arbeitsbeschreibung,
          tageskalender: this.tageskalender,
          abgeschlossen: this.abgeschlossen,
          kunde: this.kunde.kundenid,
          projekt: this.projekt,
          leistung: this.leistung
        }).then((response) => {
          if (response.data.protokollid) {
            const promisesForAsync = []

            const protokollid = response.data.protokollid
            // Alle Materialien speichern
            this._.forEach(this.materialVorgabe, function (m) {
              Vue.axios.post('/material/post', {
                protokoll: protokollid,
                stueck: m.stueckzahl,
                artikelnr: m.artikelnr,
                material: m.material
              })
            })
            this._.forEach(this.material, function (m) {
              promisesForAsync.push(Vue.axios.post('/material/post', {
                protokoll: protokollid,
                stueck: m.stueckzahl,
                artikelnr: m.artikelnr,
                material: m.material
              }))
            })
            // Alle Arbeitszeiten speichern
            this._.forEach(this.arbeitszeit, (a) => {
              let taetigkeit = a.taetigkeit
              if (!taetigkeit) {
                taetigkeit = ''
              }
              const arbeitszeitPromise = Vue.axios.post('/arbeitszeit/post', {
                protokoll: protokollid,
                auto: a.auto || false,
                mitarbeiter: a.mitarbeiter,
                datum: this.dateFormatter(a.datum),
                dauer: a.dauer,
                taetigkeit: taetigkeit
              }).then((response) => {
                // Bei automatischen Einträgen die Bilder übertragen und den Tageskalender-Eintrag löschen
                if (a.auto) {
                  const arbeitszeitid = response.data.arbeitszeitid
                  const tageskalenderid = a.tageskalenderid
                  promisesForAsync.push(Vue.axios.delete('/tageskalender/deleteauto/' + arbeitszeitid + '/' + tageskalenderid))
                }
                // Bei neuen Einträgen ggf. die Bilder speichern
                if (!a.auto) {
                  self.saveImage(response.data.arbeitszeitid, 'bild01', a.image01)
                  self.saveImage(response.data.arbeitszeitid, 'bild02', a.image02)
                  self.saveImage(response.data.arbeitszeitid, 'bild03', a.image03)
                  self.saveImage(response.data.arbeitszeitid, 'bild04', a.image04)
                  self.saveImage(response.data.arbeitszeitid, 'bild05', a.image05)
                }

                Promise.all(promisesForAsync).then(values => {
                  this.setDefaults()
                  this.$snotify.success('Protokoll wird gespeichert.', {
                    position: 'rightTop',
                    timeout: 4000
                  })
                  const routeData = self.$router.resolve({ path: `/print/${protokollid}` })
                  // window.open(routeData.href, '_blank')
                  // location.reload()
                  this.successData = {
                    link: routeData.href
                  }
                  this.successModal = true
                  this.submitGoing = false
                })
              })
              promisesForAsync.push(arbeitszeitPromise)
            })
          } else {
            this.$snotify.error('Unbestimmter Fehler beim Speichern eines Protokolls.', {
              position: 'rightTop',
              timeout: 4000
            })
            this.submitGoing = false
            this.submitting = false
          }
        }).catch((error) => {
          console.log('error.response.data')
          this.formError = error.response.data
          this.submitGoing = false
          this.submitting = false
        })
      } else {
        this.$snotify.error('Bitte fülle alle Pflichtfelder aus um das Protokoll zu speichern.', {
          position: 'rightTop',
          timeout: 4000
        })
      }
    },
    closeSite () {
      this.successModal = false
      this.submitGoing = false
      location.reload()
    },
    print () {
      this.successModal = false
      window.open(this.successData.link, '_blank')
      location.reload()
    },
    dateFormatter (value) {
      console.log('test')
      console.log(value)
      console.log(moment(value, 'DD.MM.YYYY', true))
      if (moment(value, 'DD.MM.YYYY', true).isValid()) {
        value = moment(value, 'DD.MM.YYYY', true).format('DD.MM.YYYY')
      }
      console.log(value)
      return value
    },
    loadArbeitszeitenAuto () {
      const result = []
      if (this.kunde && this.kunde !== '') {
        const self = this
        Vue.axios.post('/tageskalender/zukuenftigberechnen', {
          kundenid: this.kunde.kundenid,
          projektid: this.projekt
        }).then((response) => {
          // Trigger umgehen
          if (response.data) {
            if (response.data.length > 0) {
              self._.forEach(response.data, function (tk) {
                const newArbeitszeit = {
                  auto: true,
                  tageskalenderid: tk.tageskalenderid,
                  datum: this.dateFormatter(tk.datum),
                  dauer: parseFloat(tk.dauer),
                  mitarbeiter: tk.mitarbeiter.mitarbeiterid,
                  taetigkeit: tk.taetigkeit,
                  bilder: tk.bilder
                }
                result.push(newArbeitszeit)
              })
            }
          }
        })
      }
      this.zukuenftigBerechnen = result
    },
    saveImage (arbeitszeitid, selektor, image) {
      if (image) {
        const formData = new FormData()
        formData.append('selektor', selektor)
        formData.append('dbid', arbeitszeitid)
        formData.append('target', 'arbeitszeit')
        formData.append('uploadfile', image)

        Vue.axios.post(
          '/bilder/upload',
          formData,
          {
            headers: {
              'content-type': `multipart/form-data; boundary=${formData._boundary}`
            }
          }
        )
      }
    }
  }
}

</script>

<style lang="scss">
  button.btn-protokoll-submit:disabled {
    cursor: default;
  }
</style>
